/* DESKTOP VIEW */
html {
  --footer-bg-color: white;
  --header-bg-color: #f6fdfc;
}

header {
  padding-left: 106px;
  padding-right: 106px;
  /* position: sticky; */
  /* top: 0; */
  /* opacity: 0; */
  z-index: +100;
  background-color: var(--header-bg-color);
  /* box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08); */
}

header nav * {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

header nav *[class~="active"] {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  color: var(--primary-color);
}

footer {
  padding: 30px 100px;
  font-size: 14px;
  background-color: var(--footer-bg-color);
}

footer * {
  color: #717783 !important;
  text-decoration: none;
}

footer h4 {
  color: #393A4A;
  display: inline-block;
  text-transform: uppercase;
  padding-bottom: 3px;
  margin-bottom: 15px;
  font-weight: 700;
  border-bottom: 2px solid #31B5A7;
}

footer .icon {
  display: inline-block !important;
  font-size: 26px;
  margin-right: 15px;
}

/* MOBILE */
@media screen and (max-width: 900px) {
  .hide-on-mobile {
    display: none !important;
  }

  header {
    justify-content: space-between;
  }
}

/* LAPTOPS */
@media screen and (min-width: 900px) {
  .hide-on-pc {
    display: none !important;
  }
}
