@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,800;1,400;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;0,800;0,900;1,800;1,900&display=swap');

* {
  font-family: Nunito, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  box-sizing: border-box;
}

html {
  --lazy-light-color: #fcfcfc;
  --lazy-dark-color: #f6f6f6;
  --primary-color: #31b5a7;
  --dark-primary-color: #1a7f74;
  --secondary-color: #f6faf9;
  --light-tertiary-color: #1b847a;
  --tertiary-color: #1a7f74;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.green-text {
  color: var(--dark-primary-color);
}

.lazy-loading {
  background-color: transparent;
  animation: lazy-loading 1.2s ease-in-out 0s infinite alternate both;
  transition: all 50ms linear;
}

@-webkit-keyframes lazy-loading {
  0%,
  60%,
  70%,
  80%,
  90%,
  100% {
    background-image: -webkit-linear-gradient(
      var(--lazy-dark-color),
      var(--lazy-dark-color)
    );
    background-image: linear-gradient(
      var(--lazy-dark-color),
      var(--lazy-dark-color)
    );
  }

  15% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 10%,
      var(--lazy-light-color) 25%,
      var(--lazy-dark-color) 40%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 10%,
      var(--lazy-light-color) 25%,
      var(--lazy-dark-color) 40%
    );
  }

  30% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 35%,
      var(--lazy-light-color) 50%,
      var(--lazy-dark-color) 65%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 35%,
      var(--lazy-light-color) 50%,
      var(--lazy-dark-color) 65%
    );
  }

  55% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 60%,
      var(--lazy-light-color) 75%,
      var(--lazy-dark-color) 90%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 60%,
      var(--lazy-light-color) 75%,
      var(--lazy-dark-color) 90%
    );
  }
}

@-moz-keyframes lazy-loading {
  0%,
  60%,
  70%,
  80%,
  90%,
  100% {
    background-image: -webkit-linear-gradient(
      var(--lazy-dark-color),
      var(--lazy-dark-color)
    );
    background-image: linear-gradient(
      var(--lazy-dark-color),
      var(--lazy-dark-color)
    );
  }

  15% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 10%,
      var(--lazy-light-color) 25%,
      var(--lazy-dark-color) 40%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 10%,
      var(--lazy-light-color) 25%,
      var(--lazy-dark-color) 40%
    );
  }

  30% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 35%,
      var(--lazy-light-color) 50%,
      var(--lazy-dark-color) 65%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 35%,
      var(--lazy-light-color) 50%,
      var(--lazy-dark-color) 65%
    );
  }

  55% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 60%,
      var(--lazy-light-color) 75%,
      var(--lazy-dark-color) 90%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 60%,
      var(--lazy-light-color) 75%,
      var(--lazy-dark-color) 90%
    );
  }
}

@keyframes lazy-loading {
  0%,
  60%,
  70%,
  80%,
  90%,
  100% {
    background-image: -webkit-linear-gradient(
      var(--lazy-dark-color),
      var(--lazy-dark-color)
    );
    background-image: linear-gradient(
      var(--lazy-dark-color),
      var(--lazy-dark-color)
    );
  }

  15% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 10%,
      var(--lazy-light-color) 25%,
      var(--lazy-dark-color) 40%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 10%,
      var(--lazy-light-color) 25%,
      var(--lazy-dark-color) 40%
    );
  }

  30% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 35%,
      var(--lazy-light-color) 50%,
      var(--lazy-dark-color) 65%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 35%,
      var(--lazy-light-color) 50%,
      var(--lazy-dark-color) 65%
    );
  }

  55% {
    background-image: -webkit-linear-gradient(
      135deg,
      var(--lazy-dark-color) 60%,
      var(--lazy-light-color) 75%,
      var(--lazy-dark-color) 90%
    );
    background-image: linear-gradient(
      135deg,
      var(--lazy-dark-color) 60%,
      var(--lazy-light-color) 75%,
      var(--lazy-dark-color) 90%
    );
  }
}
